//
//
//
//
//
//
//
//
//
export default {
  props: {
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {};
  },
  created: function created() {}
};